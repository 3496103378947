import { faTrashCan } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import type { Reference } from "fhir"
import { type FieldProps, type FormikValues, useFormikContext } from "formik"
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import { classNames } from "primereact/utils"
import { useEffect, useMemo, useState } from "react"

import { FormField, ReferenceDropdownField } from "commons"

import "./LabDataInputFieldList.css"

const LabDataInputFieldList = ({
  field,
  label,
  addFieldLabel = "Add item",
  fieldDefinitions,
  initialFields,
  performers,
  disabled,
  onlyNumericValues = true,
  requiredFields = [],
}: Props) => {
  const [activeFields, setActiveFields] = useState<Array<OptionalFieldDefinition>>(requiredFields)
  const { setFieldTouched, setFieldValue, unregisterField, isSubmitting } = useFormikContext<FormikValues>()

  const isRequiredField = (key: string) => requiredFields.some((reqF) => reqF.field === key)

  useEffect(() => {
    if (initialFields)
      setActiveFields([
        ...requiredFields,
        ...activeFields.filter(
          (actField) =>
            initialFields.some((initField) => initField.field === actField.field) && !isRequiredField(actField.field),
        ),
        ...initialFields.filter(
          (initField) => !activeFields.some((a) => a.field === initField.field) && !isRequiredField(initField.field),
        ),
      ])
  }, [initialFields])

  useEffect(() => {
    if (requiredFields.length) {
      setActiveFields((activeFields) => [
        ...requiredFields.filter(({ field }) => !activeFields.some((actField) => actField.field === field)),
        ...activeFields,
      ])
    }
  }, [requiredFields])

  useEffect(() => {
    isSubmitting && activeFields.forEach(({ field: fieldName }) => setFieldTouched(`${field}.${fieldName}`))
  }, [isSubmitting])

  const availableFields = useMemo(
    () => fieldDefinitions.filter((fd) => activeFields.every((af) => af.field !== fd.field)),
    [activeFields],
  )

  const addField = (fieldData: OptionalFieldDefinition) => setActiveFields([fieldData, ...activeFields])

  const removeField = (fieldData: OptionalFieldDefinition) => {
    setActiveFields(activeFields.filter((af) => af.field !== fieldData.field))
    unregisterField(`${field}.${fieldData.field}`)
    setFieldValue(`${field}.${fieldData.field}`, "")
  }

  const itemTemplate = (fieldData: OptionalFieldDefinition) => (
    <span>
      {fieldData.label.includes(fieldData.field) ? fieldData.label : `${fieldData.label} - (${fieldData.field})`}
    </span>
  )

  return (
    <FormField
      field={field}
      label={label}
      validation={(value) =>
        (!value && !!requiredFields.length) ||
        Object.values(value ?? {}).filter((v) => !!v).length < requiredFields.length ||
        !Object.entries(value ?? {}).every(([key, value]) => {
          const isRequired = isRequiredField(key)

          return (isRequired && !!value) || !isRequired
        }) ||
        requiredFields.some(({ field }) => !(field in (value as FormikValues)))
          ? "Please, fill all required lab data"
          : undefined
      }
      showInvalidState
    >
      <div className="flex flex-col divide-y space-y-2 w-full p-1 @container">
        {availableFields.length > 0 && (
          <div className="flex justify-between items-baseline">
            <Dropdown
              optionLabel="label"
              options={availableFields}
              onChange={(e) => addField(e.value)}
              filter={availableFields.length > 0}
              filterBy="label"
              className="p-inputtext-sm text-sm no-focusable border-0 ring-0 items-center hover:bg-inherit font-medium flex-row-reverse lab-data-field-list"
              placeholder={addFieldLabel}
              dropdownIcon="pi pi-plus"
              itemTemplate={itemTemplate}
              disabled={disabled}
            />
            {performers && performers.length > 0 && (
              <ReferenceDropdownField
                field="performer"
                label="Lab"
                options={performers}
                horizontal
                className="items-baseline p-1"
                inputClassName="max-w-56"
                validation={(value) => (activeFields.length && !value?.id ? "Performer is required" : undefined)}
                disabled={!!initialFields || disabled}
                showFilter={false}
              />
            )}
          </div>
        )}
        <div className="grid @2xl:grid-cols-2 gap-x-10 gap-y-4 pt-6">
          {activeFields.map((item) => {
            const isRequired = requiredFields.some((f) => f.field === item.field)
            return (
              <FormField
                key={item.field}
                field={`${field}.${item.field}`}
                validation={(value?: string) => {
                  if (onlyNumericValues)
                    return !!value && isNaN(Number(value)) && `Please enter a valid value. (Ex. 9, 9.9)`
                  else
                    return (
                      ((!!value && isNaN(Number(value))) ||
                        !/^<?\d+(?:\.\d{1,2})?$|^\d+(?:\.\d{1,2})?>?$/.test(value as string)) &&
                      "Please enter a valid value. (Ex. 9, 9.9, '<9', '9>')"
                    )
                }}
                horizontal
                label={
                  <span className="flex flex-col max-w-32">
                    <span className="text-gray-700 font-medium text-sm">{item.label}</span>
                    <span className="text-gray-400 text-xs">{item.field}</span>
                  </span>
                }
                labelContainerClassName="w-1/3"
                containerClassName="justify-center p-1"
              >
                {({ field: { name, value, onChange }, form: { touched, errors } }: FieldProps) => {
                  const showErrorFeedback = !value && isRequired && !!touched?.[field] && !!errors?.[field]

                  return (
                    <div className="flex gap-6 items-center justify-between w-full">
                      <InputText
                        aria-autocomplete="none"
                        id={name}
                        name={name}
                        value={value}
                        onChange={onChange}
                        className={classNames("p-inputtext-sm horizontal", {
                          "p-invalid": showErrorFeedback,
                        })}
                        disabled={disabled}
                      />
                      {!isRequired && !disabled ? (
                        <FontAwesomeIcon
                          icon={faTrashCan}
                          className="cursor-pointer"
                          title="Remove result"
                          onClick={() => removeField(item)}
                        />
                      ) : (
                        <span className="w-4"></span>
                      )}
                    </div>
                  )
                }}
              </FormField>
            )
          })}
        </div>
      </div>
    </FormField>
  )
}

type Props = OptionalFieldDefinition & {
  addFieldLabel?: string
  fieldDefinitions: OptionalFieldDefinition[]
  initialFields?: OptionalFieldDefinition[]
  performers?: Reference[]
  requiredFields?: OptionalFieldDefinition[]
  disabled?: boolean
  onlyNumericValues?: boolean
}

export type OptionalFieldDefinition = {
  field: string
  label: string
}

export { LabDataInputFieldList }
